.btn-option {
  background: var(--color-white);
  border: var(--color-white);
  margin-bottom: 0;
  padding: 0.5rem 1rem !important;
  width: 100%;
  text-align: left !important;
}

.btn-option--active {
  color: var(--color-white) !important;
  background: var(--color-btn) !important;
}

.list-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.label-title {
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-btn);
}

.form-account {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .list-button {
    flex-direction: column;
    margin-top: 0;
  }
}

@media only screen and (min-width: 992px) {
  .btn-option {
    margin-bottom: 1.5rem;
    padding: 1rem 1.5rem !important;
    font-size: 1.6rem !important;
  }

  .row-profile {
    width: 80%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1400px) {
  .btn-option {
    font-size: 1.6rem !important;
  }

  .label-title {
    font-size: 1.6rem;
  }
}

@media only screen and (min-width: 2000px) {
  .btn-option {
    font-size: 2.4rem !important;
  }

  .label-title {
    font-size: 2.2rem;
  }
}
