.space-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 2rem;
}

.space-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.space-title {
  color: var(--color-btn);
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0;
}

.space-btn {
  font-size: 1rem !important;
  height: 20px;
  width: 85px;
}

.space-item {
  background-color: var(--color-gray-2);
  padding: 1rem 1.5rem;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  height: 40vh;
  overflow-y: scroll;
}

.space-item__count {
  background-color: var(--color-gray-2);
  padding: 1rem 1.5rem;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
}

.space-item__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid var(--color-gray-5);
}

.space-item__title {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.space-link--btn {
  align-self: flex-end;
}

.btn--orange {
  background-color: #ffebe5 !important;
  color: var(--color-orange) !important;
  font-size: 1.2rem !important;
}

.btn--orange:hover {
  border-color: var(--color-orange) !important;
}

.btn--orange:before {
  content: url("../assets/images/download-pdf.png");
  margin-right: 5px;
  vertical-align: middle;
}

.btn--purple {
  background-color: var(--color-purple) !important;
  color: var(--color-btn) !important;
  font-size: 1.2rem !important;
}

.btn--purple:hover {
  border-color: var(--color-purple) !important;
}

.btn--purple:before {
  content: url("../assets/images/view.png");
  margin-right: 5px;
  vertical-align: middle;
}

.btn--blue {
  background-color: var(--color-blue-light) !important;
  color: var(--color-btn) !important;
  font-size: 1.2rem !important;
}

.btn--blue:hover {
  border-color: var(--color-blue-light) !important;
}

.btn--blue:before {
  content: url("../assets/images/pdf.png");
  margin-right: 5px;
  vertical-align: middle;
}

.space-history__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--color-white);
  padding: 1rem;
}

.space-item__grow {
  flex-grow: 1;
}

.space-history_img img {
  margin-top: -24px;
}

.space-history__count {
  color: var(--color-btn);
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0;
  background-color: var(--color-gray-2);
  padding: 1rem 1.5rem;
}

.space-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
}

.space-status__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.space-status__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -2rem;
  width: 30px;
}

.space-status__title {
  font-size: 0.8rem;
  font-weight: 400;
  color: black;
}

.space-badge {
  width: 100px;
  border-radius: 4px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}

.space-badge--green {
  background-color: var(--color-green);
  color: var(--color-green-dark);
}

.space-badge--green:before {
  content: url("../assets/images/completed-outline.png");
  vertical-align: middle;
  margin-right: 0.5rem;
}

.space-badge--pink {
  background-color: var(--color-pink);
  color: var(--color-pink-dark);
}

.badge-state {
  align-self: flex-end;
}

.space-badge--pink:before {
  content: url("../assets/images/alarm.png");
  vertical-align: middle;
  margin-right: 0.5rem;
}

.table-invoice th {
  border: none !important; /* Quitar bordes del encabezado */
}

.table-invoice tbody tr:first-child td {
  border-top: none !important; /* Elimina el borde superior del tbody */
}

.table-invoice tbody tr {
  font-size: 1.4rem;
}

.space-item_info,
.space-item_state,
.space-item_btn {
  width: 100%;
}

.options-not-records {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

@media only screen and (min-width: 1200px) {
  .space-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .space-item__container {
    flex-direction: row;
  }

  .space-item_info {
    width: 22%;
  }

  .space-item_state {
    width: 35%;
  }

  .space-item_btn {
    width: 14%;
  }

  .space-link--btn {
    align-self: flex-start;
  }

  .space-item__title {
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 1600px) {
  .space-item_info {
    width: 24%;
  }

  .space-item_state {
    width: 35%;
  }

  .space-item_btn {
    width: 14%;
  }

  .space-item__title {
    font-size: 1.4rem;
  }
}
