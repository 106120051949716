.form-input {
  height: 43px !important;
  background: rgba(52, 37, 128, 0.09);
  border: 1px solid var(--color-btn);
  border-radius: 5px;
  outline: none;
  font-weight: 400;
  font-size: 1.5rem !important;
  color: var(--color-blue-text);
}

.icon-email {
  background: url("../assets/images/email.svg");
  display: inline-block;
  width: 18px;
  height: 18px;
  position: absolute;
  right: 8px;
  top: 14px;
}

.form-section {
  width: 100%;
}

.dsk-container__img {
  width: 100%;
}

.icon-password {
  background: url("../assets/images/password.svg");
  display: inline-block;
  width: 18px;
  height: 18px;
  position: absolute;
  right: 8px;
  top: 14px;
}

.text-small {
  font-weight: 400;
  font-size: 1.2rem;
  color: #2d2d2d;
  text-align: center;
}

.text-small__link {
  color: var(--color-blue-text);
  margin-left: 0.5rem;
  font-size: 0.85rem;
}

.icon-google {
  background: url("../assets/images/icon-google.svg");
  width: 22px;
  height: 22px;
  position: absolute;
  top: 14px;
  left: 33px;
}

.form-check {
  appearance: none;
  border: 1px solid var(--color-blue-text);
  width: 19px;
  height: 19px;
  border-radius: 5px;
  margin-right: 0.5rem;
}

.form-check-label {
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-blue-text);
  margin-bottom: 0;
  cursor: pointer;
}

.form-group-check {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.group-check {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  width: 100%;
}

.group-check--term {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  width: 100%;
}

.group-image__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6rem;
}

.group-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
}

.group-image--password {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.form-login {
  max-width: 350px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bg-dsk {
  background: var(--color-blue);
  min-height: 100vh;
  display: none;
}

.dsk-title {
  font-style: normal;
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 24px;
  text-align: center;
  color: var(--color-white);
  width: 300px;
}

.dsk-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 22px;
  text-align: center;
  color: var(--color-white);
  width: 400px;
}

.dsk-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dsk-image {
  margin-top: 5rem;
}

.text-account {
  color: var(--color-btn);
  font-size: 1.5rem;
  width: 350px;
  text-align: center;
  margin: 0 auto 2rem;
}

.container-reset {
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.title-account {
  color: var(--color-btn);
  font-size: 2.9rem;
  width: 222px;
  margin: 2rem auto;
  text-align: center;
}

.mx-auto {
  margin: 0 auto 1rem;
  text-align: center;
}

.form-check:checked:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 5px;
  width: 12px;
  height: 2px;
  background: var(--color-btn);
  transform: rotate(-45deg);
}

.form-check:checked:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 2px;
  width: 6px;
  height: 2px;
  background: var(--color-btn);
  transform: rotate(45deg);
}

@media only screen and (min-width: 768px) {
  .group-image {
    display: none;
  }

  .group-image__logo {
    margin-bottom: 10rem;
  }

  .bg-dsk {
    display: block;
  }

  .form-login {
    min-height: 90vh;
  }

  .text-small__link {
    font-size: 1.4rem;
  }

  .form-check-label {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 1200px) {
  .form-login {
    max-width: 450px;
  }

  .form-section {
    width: 90%;
  }

  .form-input {
    height: 52px !important;
  }

  .form-check-label {
    font-size: 1.4rem;
  }
}
