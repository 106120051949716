.title {
  color: var(--color-btn);
  font-size: 2rem;
  font-weight: 700;
}

.item__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.item {
  width: 50%;
}

.item__title {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.item-line {
  border-bottom: 1px solid var(--color-gray-5);
}

.item-hidden {
  display: none;
}

.item-subtitle {
  color: var(--color-btn);
  font-size: 1.4rem;
  font-weight: 600;
}

.table th {
  border: none !important; /* Quitar bordes del encabezado */
  font-size: 1.3rem;
}

.table tbody tr {
  font-size: 1.2rem;
}

@media only screen and (min-width: 500px) {
  .item__title {
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 900px) {
  .item__title {
    font-size: 1.4rem;
  }

  .table th {
    font-size: 1.4rem;
  }

  .table tbody tr {
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 1400px) {
  .item-hidden {
    display: inline-block;
  }
  .item__title {
    font-size: 1.6rem;
  }

  .item-subtitle {
    font-size: 1.6rem;
  }

  .table tbody tr {
    font-size: 1.5rem;
  }
}
