/* lato-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/lato-v24-latin-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lato-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  src: url("../assets/fonts/lato-v24-latin-300italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lato-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/lato-v24-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lato-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/lato-v24-latin-italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lato-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/lato-v24-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lato-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src: url("../assets/fonts/lato-v24-latin-700italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lato-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: url("../assets/fonts/lato-v24-latin-900.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lato-900italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: italic;
  font-weight: 900;
  src: url("../assets/fonts/lato-v24-latin-900italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

:root {
  --color-btn: #342580;
  --color-blue-text: #2f245b;
  --color-gray: #ced3d8;
  --color-gray-1: #e5e5e5;
  --color-blue: #0192df;
  --color-white: #ffffff;
  --color-orange: #ff6238;
  --color-gray-2: #fbfbfc;
  --color-gray-3: #b3b3c6;
  --color-gray-4: #555555;
  --color-gray-5: #c5c8da;
  --color-purple: #e4dfff;
  --color-blue-light: #eaefff;
  --color-green: #e6ffcd;
  --color-green-dark: #00a832;
  --color-pink: #ffe8ed;
  --color-pink-dark: #ef879f;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  border: none;
  outline: none;
}

html {
  font-size: 62.5%;
  font-family: "Lato", sans-serif;
}

body {
  background: var(--color-white);
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-gray);
  border-radius: 5px;
}

textarea {
  resize: none;
}

.btn {
  font-size: 1.6rem;
  border-radius: 5px !important;
  font-weight: 400;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn-secondary {
  background: var(--color-orange);
  color: var(--color-white);
  border-color: var(--color-orange);
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: var(--color-white);
  background-color: var(--color-orange);
  border-color: var(--color-orange);
  -webkit-box-shadow: 0 0 0 0.2rem rgb(130 138 145 / 50%);
  box-shadow: 0 0 0 0.2rem rgb(255 98 56 / 50%);
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: var(--color-white);
  background-color: var(--color-orange);
  border-color: var(--color-orange);
}

.btn-secondary[disabled]:hover {
  color: var(--color-white);
  background-color: #9fa5aa;
  border-color: #9fa5aa;
}

.btn-secondary:hover {
  color: var(--color-white);
  background-color: rgb(255 98 56 / 85%);
  border-color: rgb(255 98 56 / 85%);
}
.btn-primary {
  background: var(--color-btn);
  font-weight: 400;
  border-color: var(--color-btn);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: var(--color-white);
  background-color: rgb(52 37 128 / 90%);
  border-color: var(--color-btn);
}

.btn-outline-primary {
  border-color: var(--color-btn);
  font-weight: 400;
  color: var(--color-blue-text);
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: var(--color-white);
  background-color: var(--color-orange);
  border-color: var(--color-orange);
  -webkit-box-shadow: 0 0 0 0.2rem rgb(130 138 145 / 50%);
  box-shadow: 0 0 0 0.2rem rgb(255 98 56 / 50%);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #342580 !important;
  background-color: transparent;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: var(--color-white);
  background-color: #342580;
  border-color: #342580;
}

.btn-outline-primary[disabled]:hover {
  border-color: var(--color-btn);
  font-weight: 400;
  color: var(--color-blue-text);
  background-color: white;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: rgb(52 37 128 / 85%);
  border-color: rgb(52 37 128 / 85%);
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: rgb(52 37 128 / 75%);
  border-color: rgb(52 37 128 / 75%);
  box-shadow: none;
}

.btn-primary:hover {
  color: #ffffff;
  background-color: rgb(52 37 128 / 75%);
  border-color: rgb(52 37 128 / 75%);
}

a:hover {
  color: rgb(52 37 128 / 80%);
  text-decoration: none;
}

.container-main {
  padding-bottom: 10rem;
}

.btn-new-retiro {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem !important;
  height: 42px;
  font-size: 1.3rem !important;
}

.btn-image {
  margin-right: 0.7rem;
  display: inline-block;
}

.panel-account {
  text-align: right;
  padding-right: 0rem;
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-top: 7rem;
  color: var(--color-btn);
}

.form__input,
.form-group-select {
  height: 46px !important;
  border-radius: 5px;
  border: 0.025rem solid var(--color-btn);
  color: var(--color-gray-4) !important;
  font-size: 1.4rem;
}

.form__withadral_input,
.form__withadral_select {
  height: 33px !important;
  border-radius: 5px;
  border: 0.025rem solid var(--color-btn);
  color: var(--color-gray-4) !important;
  font-size: 1.3rem;
}

.form__area {
  border-radius: 5px;
  border: 0.025rem solid var(--color-btn);
  color: var(--color-gray-4) !important;
  font-size: 1.2rem;
}

.form__withadral_area {
  border-radius: 5px;
  border: 0.025rem solid var(--color-btn);
  color: var(--color-gray-4) !important;
  font-size: 1.3rem;
}

.p-relative {
  position: relative;
}

.container-password {
  position: absolute !important;
  top: 17%;
  right: 4%;
  cursor: pointer;
}

::-ms-reveal {
  display: none;
}

.w-100 {
  width: 100% !important;
}

.form-group {
  margin-bottom: 0px;
}

.form-control:focus {
  color: var(--color-gray-4) !important;
  background-color: var(--color-white);
  border-color: var(--color-btn);
  box-shadow: 0 0 0 0.2rem rgb(52 37 128 / 25%);
}

.col-dsk {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.input-item {
  position: relative;
  width: 100%;
  margin-top: 4rem;
  order: 2;
}

.input-main {
  background: var(--color-gray-2);
  border-radius: 5px;
  height: 42px;
  width: 100%;
  padding-left: 4rem;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: var(--color-btn);
  border: 0.5px solid #c5c8da;
}

.input-main--icon {
  background: url("../assets/images/search-blue.png");
  width: 17px;
  height: 17px;
  display: inline-block;
  position: absolute;
  left: 9px;
  top: 13px;
}

.input-main--delivery {
  background: url("../assets/images/group-search.png");
  width: 129px;
  height: 41px;
  display: inline-block;
  position: absolute;
  right: 9px;
  top: -4px;
}

.input-search::placeholder,
.input-main::placeholder {
  color: var(--color-btn);
}

.options-icon {
  order: 1;
}

.option-item__icon {
  width: 42px;
  height: 42px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(52, 37, 128, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.option-item__icon:hover {
  background: rgb(255 98 56 / 50%);
}

.accordion .card {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  background: #fbfbfc;
}

.card-header {
  background: #fbfbfc;
  padding: 1rem 1rem 2rem;
  border-bottom: none !important;
  cursor: pointer;
}

.card-header .accordion-text {
  background: transparent !important;
  color: var(--color-btn);
  font-weight: 700;
  margin-bottom: 0;
}

.accordion-header img {
  object-fit: contain;
}

.accordion .card-body {
  font-size: 1.4rem;
}

.list-unstyled {
  padding-left: 1rem !important;
}

.list-unstyled li {
  list-style-type: square;
}

.form__label {
  color: var(--color-orange);
  font-size: 1.4rem;
  font-weight: 400;
}

.btn-save {
  height: 42px;
  width: 120px;
}

.btn-save__block {
  width: 48%;
  height: 42px;
}

.btn-claim {
  height: 46px;
}

.label-hidden {
  color: var(--color-white);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f5f5f5;
  opacity: 1;
}

.got-back {
  text-align: right;
  margin-right: 5rem;
  width: 100%;
  font-size: 1.5rem;
  color: var(--color-blue-text);
}

.got-back__link {
  color: var(--color-blue-text);
}

.got-back__link::before {
  content: url("../assets/images/down-arrow-return.svg");
  display: inline-block;
  margin-right: 0.5rem;
}

.modal-spinner .modal-content {
  background: transparent !important;
  border: none;
}

.modal-spinner .modal-content p {
  font-size: 1.5rem;
}

.padding-menu-top {
  padding-top: 10rem;
}

.modal-close {
  cursor: pointer;
}

.date-from {
  height: 38px;
  font-size: 1.3rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.date-from__calendar {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 1.4rem !important;
  color: var(--color-btn) !important;
  content: ">" !important;
}

.breadcrumb-item.active {
  color: var(--color-btn) !important;
}

.breadcrumb {
  background-color: var(--color-white) !important;
  padding: 1rem !important;
  margin-bottom: 0 !important;
  margin-left: 3rem !important;
  font-size: 1.4rem;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
  text-align: center;
  margin: 0.166rem;
  font-size: 1.3rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.3rem;
}

.react-datepicker-wrapper {
  width: 100%;
}

.fixed-btn {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-table {
  display: flex;
  flex-direction: column;
  height: 37vh;
}

.page-item.active .page-link {
  color: #fff !important;
  background-color: #342580 !important;
  border-color: #342580 !important;
}

.page-link {
  color: #7b7b7c !important;
}

.rows-empty {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 400;
}

.table-order-hover {
  color: var(--color-btn) !important;
}

.container-pdf {
  position: absolute;
  right: 0;
  left: 0;
  height: "90vh";
  background: var(--color-white);
  padding-top: 15rem;
  text-align: center !important;
  margin-bottom: 3rem !important;
  padding-bottom: 3rem !important;
}

.dropdown-user,
.dropdown-user:hover,
.dropdown-user:focus,
.show > .dropdown-user,
.dropdown-toggle,
.dropdown-user:hover {
  color: black !important;
  background-color: var(--color-white) !important;
  border: var(--color-white) !important;
  box-shadow: none !important;
}

.dropdown-user__name {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0;
}

.dropdown-user__internal {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 0;
}

.dropdown-user {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.dropdown-container {
  order: 3;
}

.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  right: 0 !important;
}

.dropdown-menu {
  font-family: "Lato", sans-serif;
  font-size: 1.4rem;
  width: 100%;
  display: flex !important;
  justify-content: flex-end;
  align-items: flex-end;
  border: none;
  background: transparent;
}

.dropdown-link__item {
  width: 70%;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-item {
  padding: 1rem 1.5rem;
}

.dropdown-item:hover {
  color: var(--color-white);
  background-color: rgb(52 37 128 / 50%);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--color-white);
  text-decoration: none;
  background-color: rgb(52 37 128 / 50%);
}

.h-100vh {
  height: 100vh;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

@media only screen and (min-width: 768px) {
  .flex-table {
    height: 45vh;
  }

  .row-dsk {
    padding-bottom: 5rem;
  }

  .padding-menu-top {
    padding-top: 0;
  }

  .panel-account {
    margin-top: 2rem;
    font-size: 1.6rem;
    padding-bottom: 1rem;
  }

  .container-main {
    padding-bottom: 4rem;
  }

  .accordion .card-body {
    font-size: 1.5rem;
  }

  .accordion {
    padding-top: 5rem;
  }

  .card-header {
    padding: 2rem 1rem 2rem;
  }

  .input-main {
    font-size: 1.4rem;
  }

  .text-danger {
    font-size: 1.3rem;
  }

  .input-item {
    order: 1;
    margin-top: 0;
  }
}

@media only screen and (min-width: 992px) {
  .container-main {
    padding: 0 3rem 10rem;
  }
}

@media only screen and (min-width: 1200px) {
  .flex-table {
    height: 65vh;
  }

  .input-main--delivery {
    background: url("../assets/images/group-search-1.png");
    width: 187px;
    height: 60px;
    display: inline-block;
    position: absolute;
    right: 9px;
    top: -19px;
  }

  .btn-new-retiro {
    font-size: 1.4rem !important;
  }
}

@media only screen and (min-width: 1400px) {
  .form__area {
    font-size: 1.4rem;
  }

  .input-group-text {
    font-size: 1.4rem !important;
  }

  .form__label {
    font-size: 1.6rem;
  }
}

@media only screen and (min-width: 2000px) {
  .form__input,
  .form-group-select {
    height: 60px !important;
    font-size: 2rem;
  }

  .form__area {
    font-size: 2rem;
  }

  .input-group-text {
    font-size: 2rem !important;
  }

  .container-password svg {
    height: 3rem;
  }

  .container-password {
    top: 25%;
    right: 2%;
  }

  .input-main {
    font-size: 1.8rem !important;
    height: 62px;
  }

  .input-main--icon {
    top: 22px;
  }

  .input-main--delivery {
    top: 0px;
  }

  .btn-new-retiro {
    font-size: 1.8rem !important;
    height: 62px;
  }

  .card-header .accordion-text {
    font-size: 2rem;
  }

  .accordion .card-body {
    font-size: 1.8rem;
  }

  .card-header {
    padding: 3rem 2rem;
  }

  .form__label {
    font-size: 2.2rem;
  }

  .btn-save {
    font-size: 2.2rem !important;
    width: 200px;
    height: 50px;
  }

  .btn-save__block {
    font-size: 2.2rem !important;
    height: 50px;
  }
}
